import $ from 'jquery';

$(document).ready(function() {
  // Hide all panels initially
  var allPanels = $(".templatemo-accordion > li > ul").hide();

  // Accordion click functionality
  $(".templatemo-accordion > li > a").click(function() {
    var targetPanel = $(this).next();
    if (!targetPanel.hasClass("active")) {
      allPanels.removeClass("active").slideUp();
      targetPanel.addClass("active").slideDown();
    }
    return false;
  });

  // Product links functionality
  $(".product-links-wap a").click(function() {
    var thisSrc = $(this).children("img").attr("src");
    $("#product-detail").attr("src", thisSrc);
    return false;
  });

  // Quantity decrement
  $("#btn-minus").click(function() {
    var val = parseInt($("#var-value").html(), 10); // Ensure val is a number
    if (val > 1) {
      val--;
      $("#var-value").html(val);
      $("#product-quanity").val(val);
    }
    return false;
  });

  // Quantity increment
  $("#btn-plus").click(function() {
    var val = parseInt($("#var-value").html(), 10); // Ensure val is a number
    val++;
    $("#var-value").html(val);
    $("#product-quanity").val(val);
    return false;
  });

  // Size selection functionality
  $(".btn-size").click(function() {
    var thisVal = $(this).html();
    $("#product-size").val(thisVal);
    $(".btn-size").removeClass("btn-secondary").addClass("btn-success");
    $(this).removeClass("btn-success").addClass("btn-secondary");
    return false;
  });
});
