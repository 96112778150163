import React, { useState, useEffect } from 'react'; 
import { useLocation, useNavigate } from 'react-router-dom';
import SmallProductImage from './smallproductimg';
import services from './apiServices';
import {
    Button, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel, Typography, Paper, Grid, Box, Stepper, Step,
    StepLabel, Divider, Container, Dialog, DialogActions, DialogContent, DialogTitle, TextField
} from '@mui/material';

const ProductPurchase = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [cartProducts, setCartProducts] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState('Billing_Address');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [currentStep, setCurrentStep] = useState(0);
    const [addressDetails, setAddressDetails] = useState({
        Billing_Address: {
            Dispalyname:'', Mobilenumber:'',
            address1: '',
            address2: '',
            address3: '',
            city: '',
            pin: ''
        },
        Shipping_Address: {
            Dispalyname:'', Mobilenumber:'',
            address1: '',
            address2: '',
            address3: '',
            city: '',
            pin: ''
        }
    });
    const [dialogOpen, setDialogOpen] = useState(false);
    const [currentAddressType, setCurrentAddressType] = useState('');
    const [formValues, setFormValues] = useState({
        Dispalyname:'', Mobilenumber:'',
        address1: '',
        address2: '',
        address3: '',
        city: '',
        pin: ''
    });
    const [formErrors, setFormErrors] = useState({});

    const steps = ['Cart', 'Delivery Address', 'Payment Options'];

    const selectedProducts = location.state?.selectedProducts || [];

    useEffect(() => {
        services.viewcartprodinpurchase().then(response => {
            setCartProducts(response || []);
        }).catch(error => {
            console.error('Error fetching cart products:', error);
        });

        // Fetch addresses from the server
        // services.getAddresses()
        //     .then((data) => {
        //         if (data.billing) {
        //             setAddressDetails((prevDetails) => ({
        //                 ...prevDetails,
        //                 Billing_Address: data.billing,
        //             }));
        //         }
        //         if (data.shipping) {
        //             setAddressDetails((prevDetails) => ({
        //                 ...prevDetails,
        //                 Shipping_Address: data.shipping,
        //             }));
        //         }
        //     })
        //     .catch((error) => {
        //         console.error('Error fetching addresses:', error);
        //     });
    }, []);
    
    // const getDefaultAddress = () => {
    //     return addressDetails.find(address => address.defaultAddress) || null;
    // };

    const getDefaultAddress = () => {
        if (addressDetails.Billing_Address && addressDetails.Billing_Address.defaultAddress) {
            return 'Billing_Address';
        }
        if (addressDetails.Shipping_Address && addressDetails.Shipping_Address.defaultAddress) {
            return 'Shipping_Address';
        }
        return 'Billing_Address'; 
    };
    

    // useEffect(() => {
    //     const fetchData = async () => {
    //         const addresses = await services.getAddresses(); 
    //         setAddressDetails(addresses);
    //         setSelectedAddress(getDefaultAddress());
    //     };

    //     fetchData();
    // }, []);

    const handleDialogOpen = (addressType) => {
        setCurrentAddressType(addressType);
        setFormValues({
            Dispalyname: addressDetails[addressType]?.Dispalyname || '',
            Mobilenumber: addressDetails[addressType]?.Mobilenumber || '',
            address1: addressDetails[addressType]?.address1 || '',
            address2: addressDetails[addressType]?.address2 || '',
            address3: addressDetails[addressType]?.address3 || '',
            city: addressDetails[addressType]?.city || '',
            pin: addressDetails[addressType]?.pin || '',
        });
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [id]: value,
        }));
    };

    const validateForm = () => {
        const errors = {};
        Object.keys(formValues).forEach((key) => {
            if (!formValues[key]) {
                errors[key] = 'This field is required';
            }
        });
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSaveAddress = () => {
        if (!validateForm()) return;

        const updatedAddress = {
            Dispalyname: formValues.Dispalyname,
            Mobilenumber: formValues.Mobilenumber,
            address1: formValues.address1,
            address2: formValues.address2,
            address3: formValues.address3,
            city: formValues.city,
            pin: formValues.pin,
        };

        const updateAddress = async () => {
            try {
                if (addressDetails[currentAddressType]) {
                    await services.updateUserAddress(updatedAddress);
                } else {
                    await services.addUserAddress(updatedAddress);
                }
                setAddressDetails((prevDetails) => ({
                    ...prevDetails,
                    [currentAddressType]: updatedAddress,
                }));
                handleDialogClose();
            } catch (error) {
                console.error('Error saving address:', error);
            }
        };

        updateAddress();
    };

    const calculateTotalAmount = () => {
        return selectedProducts.reduce((total, product) => total + (product.PROD_PRICE * product.quantity), 0);
    };

    const renderAddressSelection = () => (
        <Box>
            <Typography variant="h6" gutterBottom>Select Delivery Address</Typography>
            <FormControl component="fieldset">
                <FormLabel component="legend">Choose Address</FormLabel>
                <RadioGroup
                    aria-label="address"
                    name="address"
                    value={selectedAddress}
                    onChange={(e) => setSelectedAddress(e.target.value)}
                >
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={8}>
                            <FormControlLabel
                                value="Billing_Address"
                                control={<Radio />}
                                label={`Billing Address: ${addressDetails.Billing_Address?.Dispalyname || 'N/A'}, ${addressDetails.Billing_Address?.Mobilenumber || 'N/A'}, ${addressDetails.Billing_Address?.address1 || 'N/A'}, ${addressDetails.Billing_Address?.address2 || 'N/A'}, ${addressDetails.Billing_Address?.address3 || 'N/A'}, ${addressDetails.Billing_Address?.city || 'N/A'}, ${addressDetails.Billing_Address?.pin || 'N/A'}`}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="outlined" color="primary" onClick={() => handleDialogOpen('Billing_Address')}>Edit</Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={8}>
                            <FormControlLabel
                                value="Shipping_Address"
                                control={<Radio />}
                                label={`Shipping Address: ${addressDetails.Shipping_Address?.Dispalyname || 'N/A'}, ${addressDetails.Shipping_Address?.Mobilenumber || 'N/A'}, ${addressDetails.Shipping_Address?.address1 || 'N/A'}, ${addressDetails.Shipping_Address?.address2 || 'N/A'}, ${addressDetails.Shipping_Address?.address3 || 'N/A'}, ${addressDetails.Shipping_Address?.city || 'N/A'}, ${addressDetails.Shipping_Address?.pin || 'N/A'}`}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="outlined" color="primary" onClick={() => handleDialogOpen('Shipping_Address')}>Edit</Button>
                        </Grid>
                    </Grid>
                </RadioGroup>
            </FormControl>
            <Divider style={{ margin: '20px 0' }} />
        </Box>
    );    

    const renderPaymentOptions = () => (
        <Box>
            <Typography variant="h6" gutterBottom>Select Payment Method</Typography>
            <FormControl component="fieldset">
                <FormLabel component="legend">Payment Method</FormLabel>
                <RadioGroup
                    aria-label="payment-method"
                    name="payment-method"
                    value={paymentMethod}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                >
                    <FormControlLabel value="credit" control={<Radio />} label="Credit Card" />
                    <FormControlLabel value="debit" control={<Radio />} label="Debit Card" />
                    <FormControlLabel value="paypal" control={<Radio />} label="PayPal" />
                </RadioGroup>
            </FormControl>
        </Box>
    );

    const renderCartSummary = () => (
        <Box className="purchase-container">
            <Typography variant="h4" gutterBottom>Purchase Summary</Typography>
            <Typography variant="h6">Item Count: {selectedProducts.length}</Typography>
            <Typography variant="h6">Total Amount: ₹{calculateTotalAmount()}</Typography>
            {selectedProducts.length === 0 ? (
                <div className="purchase-empty">No products to display</div>
            ) : (
                <Grid container spacing={2}>
                    {selectedProducts.map((product, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Box
                                className="purchase-item"
                                style={{ display: 'flex', alignItems: 'center', border: '1px solid #ddd', borderRadius: '8px' }}
                            >
                                <SmallProductImage
                                    imagePath={product.PROD_IMG_URL}
                                    alt={product.PROD_DESP}
                                />
                                <Box>
                                    <Typography variant="h5">{product.PROD_DESP}</Typography>
                                    <Typography variant="body1">Price: ₹{product.PROD_PRICE}</Typography>
                                    <Typography variant="body1">Quantity: {product.quantity}</Typography>
                                    <Typography variant="body1">Total: ₹{product.PROD_PRICE * product.quantity}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Box>
    );

    const renderStepContent = () => {
        switch (currentStep) {
            case 0:
                return renderCartSummary();
            case 1:
                return renderAddressSelection();
            case 2:
                return renderPaymentOptions();
            default:
                return null;
        }
    };

    const handleNext = async () => {
        if (currentStep === 1 && !selectedAddress) {
            alert('Please select an address');
            return;
        }
        if (currentStep === 2 && !paymentMethod) {
            alert('Please select a payment method');
            return;
        }
        
        if (currentStep === 0) {
            // Fetch address details when moving from Cart to Delivery Address
            try {
                console.log("in try")
                const data = await services.getAddresses(); // Fetch addresses from the server
                if (data.billing) {
                    setAddressDetails((prevDetails) => ({
                        ...prevDetails,
                        Billing_Address: data.billing,
                    }));
                }
                if (data.shipping) {
                    setAddressDetails((prevDetails) => ({
                        ...prevDetails,
                        Shipping_Address: data.shipping,
                    }));
                }
                setSelectedAddress(getDefaultAddress()); // Update selected address based on fetched data
            } catch (error) {
                console.error('Error fetching addresses:', error);
                alert('Failed to fetch addresses. Please try again.');
                return;
            }
        }
        
        setCurrentStep((prevStep) => prevStep + 1);
    };
    

    const handleBack = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };

    const handlePlaceOrder = () => {
        // Perform order placement logic here
        alert('Order placed successfully!');
        navigate('/');
    };

    return (
        <Container maxWidth="md" style={{ marginTop: '20px' }}>
            <Paper elevation={3} style={{ padding: '20px' }}>
                <Typography align="center" variant="h3" gutterBottom>Product Purchase</Typography>
                <Stepper activeStep={currentStep}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Box style={{ marginTop: '20px', marginBottom: '20px' }}>
                    {renderStepContent()}
                </Box>
                <Divider style={{ margin: '20px 0' }} />
                <Box display="flex" justifyContent="space-between">
                    <Button disabled={currentStep === 0} onClick={handleBack}>Back</Button>
                    {currentStep === steps.length - 1 ? (
                        <Button variant="contained" color="primary" onClick={handlePlaceOrder}>
                            Place Order
                        </Button>
                    ) : (
                        <Button variant="contained" color="primary" onClick={handleNext}>
                            Next
                        </Button>
                    )}
                </Box>
                <Box style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                    <Typography variant="h5">Total Amount: ₹{calculateTotalAmount()}</Typography>
                </Box>
            </Paper>
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Edit Address</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        id="Dispalyname"
                        label="Name"
                        value={formValues.Dispalyname}
                        onChange={handleInputChange}
                        fullWidth
                        error={!!formErrors.Dispalyname}
                        helperText={formErrors.Dispalyname}
                    />
                    <TextField
                        margin="dense"
                        id="Mobilenumber"
                        label="Mobile Number"
                        value={formValues.Mobilenumber}
                        onChange={handleInputChange}
                        fullWidth
                        error={!!formErrors.Mobilenumber}
                        helperText={formErrors.Mobilenumber}
                    />
                    <TextField
                        margin="dense"
                        id="address1"
                        label="Address 1"
                        value={formValues.address1}
                        onChange={handleInputChange}
                        fullWidth
                        error={!!formErrors.address1}
                        helperText={formErrors.address1}
                    />
                    <TextField
                        margin="dense"
                        id="address2"
                        label="Address 2"
                        value={formValues.address2}
                        onChange={handleInputChange}
                        fullWidth
                        error={!!formErrors.address2}
                        helperText={formErrors.address2}
                    />
                    <TextField
                        margin="dense"
                        id="address3"
                        label="Address 3"
                        value={formValues.address3}
                        onChange={handleInputChange}
                        fullWidth
                        error={!!formErrors.address3}
                        helperText={formErrors.address3}
                    />
                    <TextField
                        margin="dense"
                        id="city"
                        label="City"
                        value={formValues.city}
                        onChange={handleInputChange}
                        fullWidth
                        error={!!formErrors.city}
                        helperText={formErrors.city}
                    />
                    <TextField
                        margin="dense"
                        id="pin"
                        label="PIN"
                        value={formValues.pin}
                        onChange={handleInputChange}
                        fullWidth
                        error={!!formErrors.pin}
                        helperText={formErrors.pin}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">Cancel</Button>
                    <Button onClick={handleSaveAddress} color="primary">Save</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default ProductPurchase;
