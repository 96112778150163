function importAll(r) {
  let images = {};
  r.keys().forEach((item) => {
    const path = item.replace('./', '');
    images[`/img/${path}`] = r(item);
  });
  return images;
}
  
  const images = importAll(require.context('../img', true, /\.(png|jpe?g|svg)$/));
  console.log(images)
  
  export default images;