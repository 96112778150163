import React, { useState } from 'react';
import Logo from '../img/RDBS imgs/logo.png';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCartArrowDown, faUser } from '@fortawesome/free-solid-svg-icons';
import LogoutIcon from '@mui/icons-material/Logout';
import { logout } from './Logoutuser';

export default function Navheader() {
  function changeTab(tab) {
    setactivetab(tab)
  }
  const [logoutSuccess, setLogoutSuccess] = useState(false);
  const [activetab, setactivetab] = useState()

  const handleLogout = async () => {
    try {
      await logout(); // Call the logout function
      setLogoutSuccess(true); // Set the state to show success message
      setTimeout(() => {
        setLogoutSuccess(false); // Hide the success message after 3 seconds
        window.location.href = '/'; // Redirect to homepage after logout
      }, 3000);
    } catch (error) {
      console.error('Logout failed:', error.message); // Handle errors if needed
    }
  };

  // Check if user is logged in
  const isLoggedIn = !!localStorage.getItem('authToken');

  return (
    <div>
      {logoutSuccess && (
        <div className="alert alert-success text-center" role="alert">
          Logout successful!
        </div>
      )}
      <nav className="navbar navbar-expand-lg navbar-light shadow fixed-top" style={{ top: '40px', backgroundColor: 'white' }}>
        <div className="container d-flex justify-content-between align-items-center">
          <a className="navbar-brand text-success logo h1 align-self-center" href="index.html">
            <img className="r-logo" src={Logo} alt="" />
          </a>

          <button className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#templatemo_main_nav" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="align-self-center collapse navbar-collapse flex-fill d-lg-flex justify-content-lg-between" id="templatemo_main_nav">
            <div className="flex-fill">
              <ul className="nav navbar-nav d-flex justify-content-between mx-lg-auto">
                <li className="nav-item">
                 <NavLink className="nav-link" to="/"><div className={`tab-button ${activetab === 0 ? 'active' : ''}`} onClick={() => setactivetab(0)}></div>Home</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/shop"><div className={`tab-button ${activetab === 1 ? 'active' : ''}`} onClick={() => setactivetab(1)}></div>Shop</NavLink>
                  {/* <NavLink className="nav-link" to="/shop">Shop</NavLink> */}
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/contact"><div className={`tab-button ${activetab === 2 ? 'active' : ''}`} onClick={() => setactivetab(2)}></div>Contact</NavLink>
                  {/* <NavLink className="nav-link" to="/contact">Contact</NavLink> */}
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/about"><div className={`tab-button ${activetab === 3 ? 'active' : ''}`} onClick={() => setactivetab(3)}></div>About</NavLink>
                  {/* <NavLink className="nav-link" to="/about">About</NavLink> */}
                </li>
              </ul>
            </div>
            <div className="navbar align-self-center d-flex">
              <div className="d-lg-none flex-sm-fill mt-3 mb-4 col-7 col-sm-auto pr-3">
                <div className="input-group">
                  <input type="text" className="form-control" id="inputMobileSearch" placeholder="Search" />
                  <div className="input-group-text">
                    <FontAwesomeIcon icon={faSearch} />
                  </div>
                </div>
              </div>
              <NavLink className="nav-icon d-none d-lg-inline" to="/" data-bs-toggle="modal" data-bs-target="#templatemo_search">
                <FontAwesomeIcon icon={faSearch} className="text-dark mr-2" />
              </NavLink>
              <NavLink className="nav-icon position-relative text-decoration-none" to="/Addtocart">
                <FontAwesomeIcon icon={faCartArrowDown} className="text-dark mr-1" />
              </NavLink>
              <NavLink className="nav-icon position-relative text-decoration-none" to="/Loginuser">
                <FontAwesomeIcon icon={faUser} className="text-dark mr-3" />
              </NavLink>
              <button className="btn btn-outline-danger ml-3" onClick={handleLogout}>
                <LogoutIcon />
                Logout
              </button>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
