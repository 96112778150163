import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import services from './apiServices';
import bnrimgone from '../img/RDBS imgs/banner_img_01_resized.png';
import bnrimgthree from '../img/banner_img_03.jpg';
import ProductImage from './Productimage';


export default function Home() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const prodTypeId = 'Vegetables'; // Example category ID as a string
        const response = await services.fetchProductsByCategory(prodTypeId);

        // Log the response to debug
        console.log('Response:', response);

        // Handle the case when no products are found
        if (Array.isArray(response)) {
          setCategories(response);
        } else {
          setError('Unexpected response format');
        }
      } catch (error) {
        setError('Failed to fetch categories');
        console.error('Failed to fetch categories:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      <div className="modal fade bg-white" id="templatemo_search" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="w-100 pt-1 mb-5 text-right">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <form action="" method="get" className="modal-content modal-body border-0 p-0">
            <div className="input-group mb-2">
              <input type="text" className="form-control" id="inputModalSearch" name="q" placeholder="Search ..." />
              <button type="submit" className="input-group-text bg-success text-light">
                <i className="fa fa-fw fa-search text-white"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div id="template-mo-zay-hero-carousel" className="carousel slide" data-bs-ride="carousel">
        <ol className="carousel-indicators">
          <li data-bs-target="#template-mo-zay-hero-carousel" data-bs-slide-to="0" className="active"></li>
          <li data-bs-target="#template-mo-zay-hero-carousel" data-bs-slide-to="1"></li>
          <li data-bs-target="#template-mo-zay-hero-carousel" data-bs-slide-to="2"></li>
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="container">
              <div className="row p-5">
                <div className="mx-auto col-md-8 col-lg-4 order-lg-last">
                  <img className="img-fluid bimg" src={bnrimgone} alt="" />
                </div>
                <div className="col-lg-8 mb-0 d-flex align-items-center">
                  <div className="text-align-left align-self-center">
                    <h1 className="h1 text-success"><b>Prachiz</b> Masale</h1>
                    <h3 className="h2">Hand made in Pune with all natural ingredients</h3>
                    <p>
                      Prachiz... Spices is the home of our range of products which are made from premium quality gins giving our food natural color, aroma and amazing taste, our products are made in traditional method in clean environment. So every dish becomes tasty and gives a pleasant experience!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="container">
              <div className="row p-5">
                <div className="mx-auto col-md-8 col-lg-4 order-lg-last">
                  <img className="img-fluid" src="./assets/img/RDBS imgs/banner_img_02.jpg" alt="" />
                </div>
                <div className="col-lg-8 mb-0 d-flex align-items-center">
                  <div className="text-align-left">
                    <h1 className="h1">Proident occaecat</h1>
                    <h3 className="h2">Aliquip ex ea commodo consequat</h3>
                    <p>
                      You are permitted to use this Zay CSS template for your commercial websites.
                      You are <strong>not permitted</strong> to re-distribute the template ZIP file in any kind of template collection websites.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="container">
              <div className="row p-5">
                <div className="mx-auto col-md-8 col-lg-4 order-lg-last">
                  <img className="img-fluid" src={bnrimgthree} alt="" />
                </div>
                <div className="col-lg-8 mb-0 d-flex align-items-center">
                  <div className="text-align-left">
                    <h1 className="h1">Repr in voluptate</h1>
                    <h3 className="h2">Ullamco laboris nisi ut</h3>
                    <p>
                      We bring you 100% free CSS templates for your websites.
                      If you wish to support TemplateMo, please make a small contribution via PayPal or tell your friends about our website. Thank you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a className="carousel-control-prev text-decoration-none w-auto ps-3" href="#template-mo-zay-hero-carousel" role="button" data-bs-slide="prev">
          <i className="fas fa-chevron-left"></i>
        </a>
        <a className="carousel-control-next text-decoration-none w-auto pe-3" href="#template-mo-zay-hero-carousel" role="button" data-bs-slide="next">
          <i className="fas fa-chevron-right"></i>
        </a>
      </div>
      <section className="container py-5">
        <div className="row text-center pt-3">
          <div className="col-lg-6 m-auto">
            <h1 className="h1">Categories of Products</h1>
            <p>
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
              deserunt mollit anim id est laborum.
            </p>
          </div>
        </div>
        <div className="row">
          {categories.length > 0 ? (
            categories.map((category) => (
              <div key={category.PROD_ID} className="col-12 col-md-4 p-5 mt-3">
                
                <a href="#"><ProductImage  imagePath={category.PROD_IMG_URL} /></a>
                {/* <a href="#"><ProductImage  imagePath={category.PROD_IMG_URL} /></a> */}
                
                <h5 className="text-center mt-3 mb-3">{category.PROD_TYP_ID}</h5>
                <p className="text-center">
                  <Link className="btn btn-success" to={`/shop?category=${category.PROD_TYP_ID}`}>Go Shop</Link>
                </p>
              </div>
            ))
          ) : (
            <p>No categories found.</p>
          )}
        </div>
      </section>
    </div>
  );
}
