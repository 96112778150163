import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import services from './apiServices';
import ProductImage from './Productimage';

export default function Shop() {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const [shopData, setShopData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [category, setCategory] = useState(query.get('category') || '');
    const [selectedCategory, setSelectedCategory] = useState(category);

    useEffect(() => {
        const fetchShopData = async () => {
            setLoading(true);
            try {
                let response;
                if (selectedCategory === '') {
                    // Fetch all products if selectedCategory is empty (i.e., "All Categories")
                    response = await services.getAllProducts();
                } else {
                    // Fetch products based on the selected category
                    response = await services.getShopData(selectedCategory);
                }
                setShopData(response);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchShopData();
    }, [selectedCategory]);

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <>
            <div>
                <div className="container py-5">
                    <div className="row">
                        <div className="col-lg-3">
                            <h1 className="h2 pb-4">Categories</h1>
                            <ul className="list-unstyled templatemo-accordion">
                                <li className="pb-3">
                                    <a className="collapsed d-flex justify-content-between h3 text-decoration-none" href="#">
                                        Gender
                                        <i className="fa fa-fw fa-chevron-circle-down mt-1"></i>
                                    </a>
                                    <ul className="collapse show list-unstyled pl-3">
                                        <li><a className="text-decoration-none" href="#">Men</a></li>
                                        <li><a className="text-decoration-none" href="#">Women</a></li>
                                    </ul>
                                </li>
                                <li className="pb-3">
                                    <a className="collapsed d-flex justify-content-between h3 text-decoration-none" href="#">
                                        Sale
                                        <i className="pull-right fa fa-fw fa-chevron-circle-down mt-1"></i>
                                    </a>
                                    <ul id="collapseTwo" className="collapse list-unstyled pl-3">
                                        <li><a className="text-decoration-none" href="#">Sport</a></li>
                                        <li><a className="text-decoration-none" href="#">Luxury</a></li>
                                    </ul>
                                </li>
                                <li className="pb-3">
                                    <a className="collapsed d-flex justify-content-between h3 text-decoration-none" href="#">
                                        Product
                                        <i className="pull-right fa fa-fw fa-chevron-circle-down mt-1"></i>
                                    </a>
                                    <ul id="collapseThree" className="collapse list-unstyled pl-3">
                                        <li><a className="text-decoration-none" href="#">Bag</a></li>
                                        <li><a className="text-decoration-none" href="#">Sweater</a></li>
                                        <li><a className="text-decoration-none" href="#">Sunglass</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-9">
                            <div className="row">
                                <div className="col-md-6">
                                    <ul className="list-inline shop-top-menu pb-3 pt-1">
                                        <li className="list-inline-item">
                                            <a className="h3 text-dark text-decoration-none mr-3" href="#">All</a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a className="h3 text-dark text-decoration-none mr-3" href="#">Men's</a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a className="h3 text-dark text-decoration-none" href="#">Women's</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6 pb-4">
                                    <div className="d-flex">
                                        <FormControl fullWidth>
                                            <InputLabel id="category-select-label">Category</InputLabel>
                                            <Select
                                                labelId="category-select-label"
                                                id="category-select"
                                                value={selectedCategory}
                                                label="Category"
                                                onChange={handleCategoryChange}
                                            >
                                                <MenuItem value="" >All Categories</MenuItem>
                                                <MenuItem value="Vegetables">Vegetables</MenuItem>
                                                <MenuItem value="Fruits">Fruits</MenuItem>
                                                <MenuItem value="Dairy_Prod">Dairy Products</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className="container py-5">
                                <div className="row">
                                    {shopData.length > 0 ? (
                                        shopData.map(product => (
                                            <div className="col-md-4" key={product.PROD_ID}>
                                                <div className="card mb-4 product-wap rounded-0">
                                                    <div className="card rounded-0">
                                                        <ProductImage className="card-img rounded-0 img-fluid" imagePath={product.PROD_IMG_URL} alt={product.PROD_DESP} />
                                                        <div className="card-img-overlay rounded-0 product-overlay d-flex align-items-center justify-content-center">
                                                            <ul className="list-unstyled">
                                                                <Link className="btn btn-success text-white mt-2" to={`/Productdetails/${product.PROD_ID}`}><i className="far fa-eye"></i></Link><br />
                                                                {/* <Link className="btn btn-success text-white mt-2" to={`/Addtocart/${product.PROD_ID}`}> <i className="fas fa-cart-plus"></i></Link> */}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <a href="shop-single.html" className="h3 text-decoration-none">{product.PROD_DESP}</a>
                                                        <ul className="w-100 list-unstyled d-flex justify-content-between mb-0">
                                                            <li className="pt-2">
                                                                <span className="product-color-dot color-dot-red float-left rounded-circle ml-1"></span>
                                                                <span className="product-color-dot color-dot-blue float-left rounded-circle ml-1"></span>
                                                                <span className="product-color-dot color-dot-black float-left rounded-circle ml-1"></span>
                                                                <span className="product-color-dot color-dot-light float-left rounded-circle ml-1"></span>
                                                                <span className="product-color-dot color-dot-green float-left rounded-circle ml-1"></span>
                                                            </li>
                                                        </ul>
                                                        <ul className="list-unstyled d-flex justify-content-center mb-1">
                                                            <li>
                                                                <i className="text-warning fa fa-star"></i>
                                                                <i className="text-warning fa fa-star"></i>
                                                                <i className="text-warning fa fa-star"></i>
                                                                <i className="text-muted fa fa-star"></i>
                                                                <i className="text-muted fa fa-star"></i>
                                                            </li>
                                                        </ul>
                                                        <p className="text-center mb-0">₹{product.PROD_PRICE}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div>No products found</div>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <ul className="pagination pagination-lg justify-content-end">
                                    <li className="page-item disabled">
                                        <a className="page-link active rounded-0 mr-3 shadow-sm border-top-0 border-left-0" href="#" tabIndex="-1">1</a>
                                    </li>
                                    <li className="page-item">
                                        <a className="page-link rounded-0 mr-3 shadow-sm border-top-0 border-left-0 text-dark" href="#">2</a>
                                    </li>
                                    <li className="page-item">
                                        <a className="page-link rounded-0 shadow-sm border-top-0 border-left-0 text-dark" href="#">3</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
