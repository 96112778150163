import axios from 'axios';
  // Function to handle user logout
  export const logout = async () => {
    try {
    // Make the API call to logout
    await axios.post('/api/logout'); // Adjust the URL if needed
    // Optionally, handle any additional logic after logout, e.g., clearing localStorage
    } catch (error) {
    console.error('Logout failed:', error);
    throw error; // Optional: rethrow error to handle it in the component
    }
}