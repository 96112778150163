import logo from './logo.svg';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Link
} from "react-router-dom";
import './App.css';
import {Helmet} from 'react-helmet'
import Navheader from './Components/Navheader';
import Navbar from './Components/Navbar';
import Home from './Components/Home';
import Footer from './Components/Footer';
import About from './Components/About';
import Shop from './Components/Shop';
import Contact from './Components/Contact';
import Productdetails from './Components/Productdetails';
import Addtocart from './Components/Addtocart';
import Productpurchase from './Components/Productpurchase'
import Loginuser from './Components/Loginuser';
import Registrationuser from './Components/Registrationuser'

// import 'bootstrap/dist/css/bootstrap.min.css'
import './css/bootstrap.min.css'
import './css/fontawesome.css'
import './css/fontawesome.min.css'
import './css/rdbs.css'
import './css/slick-theme.css'
import './css/slick-theme.min.css'
import './css/slick.min.css'
import './css/templatemo.css'
import './css/templatemo.min.css'

import useScripts from './hooks/useScripts';
// import './js/slick.min.js'

// import './src/js/templatemo.min.js';


function App() {

  // useScripts('./js/custom.js');
  return (
    <>
    
    <Router>
      <Navbar/>
      <Routes>
        <Route path="/" element={<Home/>}/>
      </Routes>
      <Routes>
        <Route path='/about' element={<About/>}/>
      </Routes>
      <Routes>
        <Route path='/shop' element={<Shop/>}/>
      </Routes>
      <Routes>
        <Route path='/Productdetails/:id' element={<Productdetails/>}/>
      </Routes>
      <Routes>
        <Route path='/contact' element={<Contact/>}/>
      </Routes>
      <Routes>
        <Route path='/Addtocart' element={<Addtocart/>}/>
      </Routes>
      <Routes>
        <Route path='/Productpurchase' element={<Productpurchase/>}/>
      </Routes>
      <Routes>
        <Route path='/Loginuser' element={<Loginuser/>}/>
      </Routes>
      <Routes>
        <Route path='/Registrationuser' element={<Registrationuser/>}/>
      </Routes>
      <Footer/>
    </Router> 

    
    </>
  );
}

export default App;
