import React from 'react';
import images from './imageloader.js';

const SmallProductImage = ({ imagePath }) => {
  const imageSrc = images[imagePath];
  
  return (
    <img
      className="purchase-cartitem"
      src={imageSrc}
      alt="Product"
      style={{ width: '100px', height: '100px', marginRight:'30px',marginLeft:'20px', marginTop:'20px', marginBottom:'20px' }}
    />
  );
};

export default SmallProductImage;
